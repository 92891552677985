import React from "react";

const Footer = () => {
    return (
        <div style={{backgroundColor: "#555", position:'relative', left:'0', right:'0', bottom:'0', width:'100%'}}>
            <footer style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <ul>
                    <p>Insta</p>
                </ul>
                <ul>
                    <p>apple</p>
                </ul>
                <ul>
                    <p>Placeholder</p>
                </ul>
            </footer>
            <p style={{fontSize: '0.5em', display: 'flex', justifyContent: 'space-evenly'}}>© Okay Apps LLC 2023</p>
        </div>
    );
};
export default Footer;