import './App.css';

import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';

import Navbar from './components/navbar';
import Footer from './components/footer';
import Boring from './components/Boring Weather-800x400.jpeg';
import Logo from './components/logo.png';
import Crab from './components/crab.JPG';

function App() {
  return (
    <div className="App">
        <Navbar />
        <div>
          <img src={Logo} style={{width: '30%', padding: '2em', marginTop: '3em'}}/>
            <h1 style={{textAlign: 'center', fontSize: '3.5em', padding: '1em', width:'70%', margin: 'auto'}}>
              Uncomplicating the Digital World.
            </h1>
              
              <div className='Showcase' style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
                <div>
                  <h3>Pirate Cats</h3>
                  <h4>A pixel art platformer</h4>
                  <img src={Crab} style={{width: '80%', margin: '1rem'}} alt='a suprised crab'/>
                </div>
                <div>
                  <h3>Boring Weather</h3>
                  <h4>An iOS weather app</h4>
                  <img src={Boring} style={{width: '85%', margin: '1rem'}} alt='a weather app'/>
                </div>
                <div>
                  <h3>Tmerv Photography</h3>
                  <h4>A creatives portfolio</h4>
                  <img src={Crab} style={{width: '80%', margin: '1rem'}} alt='a suprised crab'/>
                </div>
              </div> 
              <div>
                <p style={{textAlign: 'center', fontSize: '1.5em', marginLeft: '1em'}}>
                Embracing innovation in iOS development with Swift, exploring modern web solutions, and taking initial strides in the realm of game development. 
                </p>
              </div>
          </div>
          <Footer />
    </div>
  );
};

export default App;
