import React from "react";

const Navbar = () => {
    return (
        <div style={{ position: 'fixed', width: '100%', backgroundColor: "#555", height: '3em'}}>
            <div style={{position: 'fixed', padding: '1em', fontWeight:'bolder'}}>
                Okay Apps
            </div>
            <nav style={{display: "flex",flexDirection: "row", justifyContent: 'flex-end', marginRight: "1.5em"}}>
                <ul>
                    <a href="/Projects">Our Work</a>
                </ul>
                <ul>
                    <a href="/Services">Our Services</a>
                </ul>
                <ul>
                <a href="/About">About Us</a>
                </ul>
            </nav>
        </div>
    );
};
export default Navbar;